<template>
  <div class="outcomes-list">
    <div
      v-for="(outcome, index) in outcomes"
      :key="outcome.uniqId"
      class="outcome-list-item"
    >
      <StCouponSeparator v-if="index !== 0" />
      <div class="outcome">
        <div v-if="!outcome.isActive" class="disabled-overlay">
          {{ t('coupon.outcomeNotAvailable') }}
        </div>
        <button
          class="remove-button"
          type="button"
          @click="removeOutcome(outcome.uniqId)"
        >
          <StIcon name="cross-large" :size="mobile ? 20 : 16" />
        </button>
        <OutcomeDetails :outcome="outcome" class="outcome-details" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  type ExtendedCouponOutcome,
  useCouponStore,
} from '../../../stores/useCouponStore'

const { removeOutcome } = useCouponStore()
const { t } = useI18n()

defineProps<{
  outcomes: ExtendedCouponOutcome[]
  mobile?: boolean
}>()
</script>

<style scoped>
.outcomes-list {
  background: var(--background-primary);
  border-radius: var(--border-radius-100);
}

.outcome {
  position: relative;
  display: flex;
}

.disabled-overlay {
  content: '';

  position: absolute;
  z-index: 1;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  font: var(--desktop-text-sm-semibold);
  color: var(--text-secondary);

  background-color: rgba(17 17 24 / 60%);
  backdrop-filter: blur(1px);
  border-radius: var(--border-radius-100);
}

.outcome-list-item:not(:first-child) .disabled-overlay {
  top: -6px;
}

.outcome-list-item:not(:last-child) .disabled-overlay {
  bottom: -6px;
}

.outcome-details {
  flex-grow: 1;
  padding: var(--spacing-050) var(--spacing-100) var(--spacing-075) 0;
}

.outcome-list-item:first-child .outcome-details {
  padding-top: var(--spacing-075);
}

.outcome-list-item:last-child .outcome-details {
  padding-bottom: var(--spacing-100);
}

.remove-button {
  all: unset;

  cursor: pointer;

  position: relative;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-075);

  color: var(--text-tertiary);

  &:hover {
    color: var(--text-primary);
  }

  &:active {
    color: var(--text-secondary);
  }
}
</style>
