<template>
  <div
    v-if="outcomeCount > 0"
    class="bet-making-form"
    data-t="bet-making-form"
    :class="{ mobile }"
  >
    <div class="tabs-row">
      <button class="header-button" @click="clear">
        <StIcon name="trash" :size="mobile ? 20 : 16" />
      </button>
      <StTabs
        v-model="tab"
        class="tabs"
        :tabs="tabs"
        :grow="!mobile"
        :type="mobile ? 'mobile-m' : 'separate'"
      />
      <button v-if="mobile" class="header-button close" @click="emit('close')">
        <StIcon name="cross-large" :size="mobile ? 20 : 16" />
      </button>
    </div>
    <OrdinaryForm
      v-if="tab === 'ordinary'"
      class="content"
      :mobile="mobile"
      @close="emit('close')"
    />
    <ExpressForm
      v-else
      class="content"
      :mobile="mobile"
      @close="emit('close')"
    />
    <div class="settings">
      <StCheckbox v-model="isAcceptedRateChange" size="s">
        {{ t('coupon.acceptWithAnyBetRate') }}
      </StCheckbox>
    </div>
  </div>
  <EmptyCoupon v-else />
</template>

<script setup lang="ts">
import type { Tab } from '@st/ui/components/StTabs/types'
import { useUserStore } from '@st/user/stores/useUserStore'
import { useFreebetsStore } from '@st/bonuses/stores/useFreebetsStore'
import OrdinaryForm from '../OrdinaryForm/OrdinaryForm.vue'
import ExpressForm from '../ExpressForm/ExpressForm.vue'
import EmptyCoupon from '../EmptyCoupon/EmptyCoupon.vue'
import { useCouponStore } from '../../stores/useCouponStore'

const couponStore = useCouponStore()
const freebetsStore = useFreebetsStore()

const { clear } = couponStore
const { outcomeCount } = storeToRefs(couponStore)

const { t } = useI18n()

defineProps<{
  mobile?: boolean
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const tabs = computed<Tab[]>(() => [
  {
    label: t('coupon.ordinary'),
    id: 'ordinary',
  },
  {
    label: t('coupon.express'),
    id: 'express',
    disabled: outcomeCount.value < 2,
  },
])

const tab = ref<'ordinary' | 'express'>(
  outcomeCount.value > 1 ? 'express' : 'ordinary',
)

watch(outcomeCount, (newValue, oldValue) => {
  if (newValue === 1) {
    tab.value = 'ordinary'
  } else if (newValue === 2 && oldValue === 1) {
    tab.value = 'express'
  }
})

const userStore = useUserStore()
const { userSettings, isAuthenticated } = storeToRefs(userStore)
const isAcceptedRateChange = computed<boolean>({
  get: () => userSettings.value?.acceptChangeBetRate === 'any',
  set: (value) => {
    if (!isAuthenticated.value) return
    userStore.updateUserSettings([
      {
        name: 'acceptChangeBetRate',
        value: value ? 'any' : 'never',
      },
    ])
  },
})

watchEffect(() => {
  if (isAuthenticated.value) freebetsStore.fetchFreebets()
})
</script>

<style scoped>
.bet-making-form {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tabs-row {
  display: flex;
  flex-shrink: 0;
  gap: var(--spacing-050);
  padding: var(--spacing-150);
}

.content {
  flex-shrink: 1;
  min-height: 0;
}

.header-button {
  all: unset;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-100);

  color: var(--text-primary);

  &:hover {
    color: var(--text-secondary);
  }
}

.settings {
  display: flex;
  align-items: center;
  justify-content: center;

  padding-bottom: var(--spacing-150);

  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);
}

.mobile {
  .tabs-row {
    display: flex;
    align-items: center;

    height: 48px;
    padding: 0 var(--spacing-050);
    padding-left: var(--spacing-150);
  }

  .tabs {
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  .header-button {
    padding: var(--spacing-125);
  }

  .close {
    color: var(--text-primary);
  }
}
</style>
