<template>
  <div class="bet-amount-input" :class="`type-${type}`">
    <div class="input-wrapper" :class="{ error, freebet: !!freebet }">
      <span v-if="freebet" class="freebet-message">
        {{ t('coupon.freebet.placeholder') }}
      </span>
      <StButton
        v-else-if="showMaxButton"
        size="s"
        :label="t('coupon.max')"
        type="ghost"
        @click="handleMaxClick"
      />
      <input
        ref="amountInput"
        v-model="amount"
        :disabled="!!freebet"
        type="text"
        class="input"
        :placeholder="t('coupon.bet')"
        inputmode="decimal"
        data-t="bet-amount-input-lakk"
        @keydown="handleKeydown"
      />
      <StIcon
        :name="freebet ? freebet.currencyIcon : currencyIcon"
        :size="16"
      />
    </div>
    <StTransitionExpand>
      <div v-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>
    </StTransitionExpand>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '@st/ui/components/StIcon/types'
import type { Freebet } from '@st/bonuses/types'

interface Props {
  modelValue: string
  maxAmount: string
  currencyIcon: IconName
  errorMessage?: string
  type?: 'express' | 'ordinary'
  error?: boolean
  showMaxButton?: boolean
  freebet?: Freebet
}

const {
  modelValue,
  maxAmount,
  currencyIcon,
  errorMessage,
  error,
  freebet,
  type = 'express',
  showMaxButton = true,
} = defineProps<Props>()

const { t } = useI18n()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const amountInput = ref<HTMLInputElement | null>(null)
const MAX_FRACTION_DIGITS = 8
function handleKeydown(event: KeyboardEvent) {
  const systemKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab']
  const separatorKeys = ['.', ',']
  const numberKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

  const { value } = event.target as HTMLInputElement

  const allowedKeys = systemKeys
  if (value && !value.includes('.')) allowedKeys.push(...separatorKeys)

  const isSelected =
    amountInput.value?.selectionStart !== amountInput.value?.selectionEnd
  const fractionDigits = value.split('.')?.[1] ?? ''
  if (fractionDigits.length < MAX_FRACTION_DIGITS || isSelected)
    allowedKeys.push(...numberKeys)

  if (!allowedKeys.includes(event.key)) {
    event.preventDefault()
  }
}

const amount = computed({
  get: () => (freebet ? freebet.amount : modelValue),
  set: (value) => {
    emit('update:modelValue', value.replace(',', '.'))
  },
})

function handleMaxClick() {
  amount.value = maxAmount
}
</script>

<style scoped>
.error-message {
  padding-top: var(--spacing-050);
  font: var(--desktop-text-xs-medium);
  color: var(--system-error);
}

.freebet-message {
  position: absolute;
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
}

.input {
  all: unset;

  flex-grow: 1;

  padding: var(--spacing-100) var(--spacing-050) var(--spacing-100)
    var(--spacing-075);

  font: var(--desktop-text-sm-medium);
  text-align: right;
}

.input-wrapper {
  display: flex;
  align-items: center;
  padding-inline: var(--spacing-075);
  border-radius: var(--border-radius-100);

  &:hover {
    box-shadow: 0 0 0 1px inset var(--button-primary-default);
  }

  &:focus-within {
    box-shadow:
      0 0 0 1px inset var(--button-primary-default),
      var(--focus-rings-button-primary);
  }

  &.error,
  &.error:hover {
    box-shadow: 0 0 0 1px inset var(--button-destructive-default);

    &:focus-within {
      box-shadow:
        0 0 0 1px inset var(--button-destructive-default),
        var(--focus-rings-button-destructive);
    }
  }

  &.freebet {
    pointer-events: none;

    .input {
      min-width: 0;
    }
  }
}

.bet-amount-input {
  &.type-express {
    .input-wrapper {
      background-color: var(--background-secondary);
    }

    .error-message {
      padding-inline: var(--spacing-100);
    }
  }

  &.type-ordinary {
    .input-wrapper {
      background-color: var(--background-base);
    }
  }
}
</style>
